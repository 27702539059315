import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"

const ContactPage = () => (
  <Background>
    <Layout>
      <Seo title="Contact" />
      <Container>
        <PageTitle text="Contact Us" />
        <p>What can you enter</p>
      </Container>
    </Layout>
  </Background>
)

export default ContactPage
